export default class EmployeesEnquirySalariesPaidsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.enquirySalarieToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.enquirySalarieToken = data.enquirySalarieToken;
    this.textSearch = data.textSearch;
  }
}
