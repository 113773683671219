import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import EmployeesEnquirySalariesPaidsFilter from "./EmployeesEnquirySalariesPaidsFilter";

export default class EmployeesEnquirySalariesPaid {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.enquirySalariesPaidToken = "";
    this.enquirySalarieToken = "";
    this.treasuryToken = "";
    this.treasuryNameCurrent = "";
    this.employeeMakeActionToken = "";
    this.employeeMakeActionNameCurrent = "";
    this.enquirySalariePaidValue = "";
    this.enquirySalariePaidRemaning = "";
    this.enquirySalariePaidNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.enquirySalariesPaidArchiveStatus = "";
  }
  fillData(data) {
    this.enquirySalariesPaidToken = data.enquirySalariesPaidToken;
    this.enquirySalarieToken = data.enquirySalarieToken;
    this.treasuryToken = data.treasuryToken;
    this.treasuryNameCurrent = data.treasuryNameCurrent;
    this.employeeMakeActionToken = data.employeeMakeActionToken;
    this.employeeMakeActionNameCurrent = data.employeeMakeActionNameCurrent;
    this.enquirySalariePaidValue = data.enquirySalariePaidValue;
    this.enquirySalariePaidRemaning = data.enquirySalariePaidRemaning;
    this.enquirySalariePaidNotes = data.enquirySalariePaidNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.enquirySalariesPaidArchiveStatus =
      data.enquirySalariesPaidArchiveStatus;
  }

  async getAllEmployeesEnquirySalariesPaids(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new EmployeesEnquirySalariesPaidsFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeesEnquirySalariesPaid/GetAllEmployeesEnquirySalariesPaid?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&enquirySalarieToken=${filterData.enquirySalarieToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addEmployeesEnquirySalariesPaid(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      enquirySalarieToken: this.enquirySalarieToken,
      treasuryToken: this.treasuryToken,
      enquirySalariePaidValue: this.enquirySalariePaidValue,
      enquirySalariePaidNotes: this.enquirySalariePaidNotes,
    };

    try {
      let response = await axios.post(
        `/EmployeesEnquirySalariesPaid/AddEmployeesEnquirySalariesPaid`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async cancelEmployeesEnquirySalariesPaid(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      enquirySalariesPaidToken: this.enquirySalariesPaidToken,
    };

    try {
      let response = await axios.post(
        `/EmployeesEnquirySalariesPaid//FinalDeleteEmployeesEnquirySalariesPaid`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
