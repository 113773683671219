<template>
  <div class="user-card-info">
    <span v-b-toggle.employeeInfo class="collapse-title">
      {{ $t("employeeInfo") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </span>
    <b-collapse id="employeeInfo">
      <div class="row">
        <div class="user-img-container col-lg-4">
          <img
            class="user-img"
            :src="BASE_URL + salaryInquiryData.employeeImagePath"
            :onerror="`this.src='${DEFAULT_IMG}'`"
          />
        </div>
        <div class="col-lg-4 col-md-6">
          <DataLabelGroup
            :value="salaryInquiryData.employeeCode"
            :title="$t('employeeCode')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :value="salaryInquiryData.employeeNameCurrent"
            :title="$t('employeeName')"
            :imgName="'man.svg'"
          />
          <DataLabelGroup
            :class="'phone-number'"
            :value="salaryInquiryData.employeePhoneWithCC"
            :title="$t('phoneNumber')"
            :imgName="'phone.svg'"
          />

          <DataLabelGroup
            :value="salaryInquiryData.employeeEmail"
            :title="$t('email')"
            :imgName="'email.svg'"
          />
          <DataLabelGroup
            :value="salaryInquiryData.employeeJobNameCurrent"
            :title="$t('Employee.jobInContract')"
            :imgName="'jobs.svg'"
          />
        </div>

        <div class="col-lg-4 col-md-6">
          <DataLabelGroup
            :value="salaryInquiryData.month"
            :title="$t('month')"
            :imgName="'date.svg'"
          />
          <DataLabelGroup
            :value="salaryInquiryData.year"
            :title="$t('year')"
            :imgName="'year.svg'"
          />
          <DataLabelGroup
            :value="salaryInquiryData.totalSalary"
            :title="$t('SalaryInquiries.totalSalary')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :value="salaryInquiryData.totalPaid"
            :title="$t('SalaryInquiries.totalPaid')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :value="salaryInquiryData.totalRemaining"
            :title="$t('SalaryInquiries.totalRemaining')"
            :imgName="'money.svg'"
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BASE_URL, DEFAULT_IMG } from "@/utils/constants";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      BASE_URL,
      DEFAULT_IMG,
    };
  },
  components: {
    DataLabelGroup,
  },
  props: ["salaryInquiryData"],
  methods: {},
  computed: {},
};
</script>
