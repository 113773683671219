<template>
  <b-modal
    id="EmployeesEnquirySalariesPaidAdd"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/pay.svg" class="icon-lg" />
        {{ $t("EmployeesEnquirySalariesPaids.paid") }}
      </h3>
    </template>
    <form autocomplete="off" method="post">
      <div class="row">
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'treasuryToken'"
          :value="salaryPaidData.treasuryToken"
          :options="treasuryTokenOptions"
          v-on:changeValue="salaryPaidData.treasuryToken = $event"
          :title="$t('selectTreasury')"
          :imgName="'treasuries.svg'"
        />

        <CustomInputFloat
          :className="'col-md-12'"
          :id="'enquirySalariePaidValue'"
          :value="salaryPaidData.enquirySalariePaidValue"
          :title="$t('paidMoney')"
          :imgName="'money.svg'"
          v-on:changeValue="salaryPaidData.enquirySalariePaidValue = $event"
        />

        <TextArea
          :className="'col-md-12'"
          :id="'enquirySalariePaidNotes'"
          :value="salaryPaidData.enquirySalariePaidNotes"
          :title="$t('notes')"
          :imgName="'notes.svg'"
          v-on:changeValue="salaryPaidData.enquirySalariePaidNotes = $event"
        />
      </div>
      <div class="modal-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addEmployeesEnquirySalariesPaid"
        >
          {{ $t("pay") }}
        </button>
        <button
          name="submit"
          type="submit"
          class="btn btn-cancel"
          @click.prevent="$bvModal.hide('EmployeesEnquirySalariesPaid')"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";

import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import TextArea from "@/components/general/TextArea.vue";
import Treasury from "@/models/finance/treasuries/Treasury";

import { moneyFormat } from "@/utils/functions";
import createToastMixin from "@/utils/create-toast-mixin";
// import Client from "@/models/clients/Client";

export default {
  name: "EmployeesEnquirySalariesPaidAdd",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,
    CustomInputFloat,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,

      treasuryData: new Treasury(),
      treasuryTokenOptions: [],
    };
  },
  watch: {},
  methods: {
    async addEmployeesEnquirySalariesPaid() {
      this.isLoading = true;
      try {
        let response =
          await this.salaryPaidData.addEmployeesEnquirySalariesPaid(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("EmployeesEnquirySalariesPaidAdd");
          this.salaryPaidData.setInitialValue();
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfTreasuries() {
      this.isLoading = true;
      try {
        this.treasuryTokenOptions =
          await this.treasuryData.getDialogOfTreasuries(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    moneyFormat(money) {
      return moneyFormat(money);
    },
  },
  props: ["salaryPaidData"],
  computed: {},
  async created() {
    this.getDialogOfTreasuries();
  },
};
</script>

<style scoped lang="scss"></style>
