<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData !=
          undefined &&
        employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData
          .length != 0
      "
    >
      <EmployeesEnquirySalariesPaidCardInfo
        :salaryInquiryData="salaryInquiryData"
      />
      <EmployeesEnquirySalariesPaidsTable
        :employeesEnquirySalariesPaidsData="
          employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData
        "
        :employeesEnquirySalariesPaidData="
          employeesEnquirySalariesPaids.employeesEnquirySalariesPaid
        "
        v-on:setEmployeesEnquirySalariesPaidData="
          employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.fillData(
            $event
          )
        "
        :pagination="employeesEnquirySalariesPaids.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="employeesEnquirySalariesPaids.pagination"
        v-on:changePage="changePage"
      />
      <EmployeesEnquirySalariesPaidCancel
        :employeesEnquirySalariesPaidData="
          employeesEnquirySalariesPaids.employeesEnquirySalariesPaid
        "
        v-on:refresh="getAllEmployeesEnquirySalariesPaids()"
      />
    </div>

    <EmployeesEnquirySalariesPaidFloatBtns
      :theFilterData="employeesEnquirySalariesPaids.filterData"
      v-on:newSalaryPayment="newSalaryPayment()"
      v-on:search="search($event)"
    />
    <EmployeesEnquirySalariesPaidAdd
      :salaryPaidData="
        employeesEnquirySalariesPaids.employeesEnquirySalariesPaid
      "
      v-on:refresh="getAllEmployeesEnquirySalariesPaids()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeesEnquirySalariesPaidsTable from "@/components/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaidsTable.vue";
import EmployeesEnquirySalariesPaidCardInfo from "@/components/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaidCardInfo.vue";
import EmployeesEnquirySalariesPaidCancel from "@/components/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaidCancel.vue";
import EmployeesEnquirySalariesPaidFloatBtns from "@/components/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaidFloatBtns.vue";
import EmployeesEnquirySalariesPaids from "@/models/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaids";
import EmployeesEnquirySalariesPaidAdd from "@/components/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaidAdd.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeesEnquirySalariesPaids",
  mixins: [createToastMixin],
  props: ["salaryInquiryData"],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeesEnquirySalariesPaidsTable,
    EmployeesEnquirySalariesPaidCardInfo,
    EmployeesEnquirySalariesPaidCancel,
    EmployeesEnquirySalariesPaidFloatBtns,
    EmployeesEnquirySalariesPaidAdd,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeesEnquirySalariesPaids: new EmployeesEnquirySalariesPaids(),
    };
  },
  methods: {
    changePage(page) {
      this.employeesEnquirySalariesPaids.pagination.selfPage = page;
      this.getAllEmployeesEnquirySalariesPaids();
    },
    search(data) {
      this.employeesEnquirySalariesPaids.filterData.fillData(data);
      this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData = [];
      this.getAllEmployeesEnquirySalariesPaids();
    },
    async getAllEmployeesEnquirySalariesPaids() {
      this.isLoading = true;
      if (!this.employeesEnquirySalariesPaids.filterData.enquirySalarieToken) {
        this.$router.push({ name: "SalaryInquiries" }).catch(() => {});
      }
      try {
        const response =
          await this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.getAllEmployeesEnquirySalariesPaids(
            this.language,
            this.userAuthorizeToken,
            this.employeesEnquirySalariesPaids.pagination,
            this.employeesEnquirySalariesPaids.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeesEnquirySalariesPaids.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData =
            [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData =
            [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData =
            [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData =
          [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    newSalaryPayment() {
      if (!this.employeesEnquirySalariesPaids.filterData.enquirySalarieToken) {
        this.$router.push({ name: "SalaryInquiries" }).catch(() => {});
      } else {
        this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.enquirySalarieToken =
          this.salaryInquiryData.enquirySalarieToken;
        this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.enquirySalariePaidValue =
          this.salaryInquiryData.totalRemaining;
      }
    },
  },
  async created() {
    if (!this.salaryInquiryData) {
      this.$router.push({ name: "SalaryInquiries" }).catch(() => {});
    } else {
      this.employeesEnquirySalariesPaids.filterData.enquirySalarieToken =
        this.salaryInquiryData.enquirySalarieToken;
      this.getAllEmployeesEnquirySalariesPaids();
    }
  },
};
</script>

<style lang="scss"></style>
