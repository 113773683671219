var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),(
      _vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData !=
        undefined &&
      _vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData
        .length != 0
    )?_c('div',[_c('EmployeesEnquirySalariesPaidCardInfo',{attrs:{"salaryInquiryData":_vm.salaryInquiryData}}),_c('EmployeesEnquirySalariesPaidsTable',{attrs:{"employeesEnquirySalariesPaidsData":_vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaidsData,"employeesEnquirySalariesPaidData":_vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid,"pagination":_vm.employeesEnquirySalariesPaids.pagination},on:{"setEmployeesEnquirySalariesPaidData":function($event){return _vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.fillData(
          $event
        )}}}),(!_vm.isLoading)?_c('Pagination',{attrs:{"paginationData":_vm.employeesEnquirySalariesPaids.pagination},on:{"changePage":_vm.changePage}}):_vm._e(),_c('EmployeesEnquirySalariesPaidCancel',{attrs:{"employeesEnquirySalariesPaidData":_vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid},on:{"refresh":function($event){return _vm.getAllEmployeesEnquirySalariesPaids()}}})],1):_vm._e(),_c('EmployeesEnquirySalariesPaidFloatBtns',{attrs:{"theFilterData":_vm.employeesEnquirySalariesPaids.filterData},on:{"newSalaryPayment":function($event){return _vm.newSalaryPayment()},"search":function($event){return _vm.search($event)}}}),_c('EmployeesEnquirySalariesPaidAdd',{attrs:{"salaryPaidData":_vm.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid},on:{"refresh":function($event){return _vm.getAllEmployeesEnquirySalariesPaids()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }