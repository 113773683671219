import PaginationData from "@/models/general/PaginationData";
import EmployeesEnquirySalariesPaid from "./EmployeesEnquirySalariesPaid";
import EmployeesEnquirySalariesPaidsFilter from "./EmployeesEnquirySalariesPaidsFilter";

export default class EmployeesEnquirySalariesPaids {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.employeesEnquirySalariesPaidsData = [];
    this.employeesEnquirySalariesPaid = new EmployeesEnquirySalariesPaid();
    this.filterData = new EmployeesEnquirySalariesPaidsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.employeesEnquirySalariesPaidPagination);
    this.employeesEnquirySalariesPaidsData =
      data.employeesEnquirySalariesPaidPagination.employeesEnquirySalariesPaidData;
  }
}
